import {
  AddCircleOutlineOutlinedIcon,
  Box,
  Button,
  CircularProgress,
  CustomModalTypes,
  Pagination,
} from "@enerbit/base";

import { ChangeEvent, Fragment, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import "../../../assets/css/notification.scss";
import {
  count,
  getNotificatios,
  handlePageChange,
  isLoadingNotification,
  page,
  setResetVars,
} from "../../../features/notification/notification";
import { PropsNotifications } from "../../../models/notificationState";
import { AppDispatch } from "../../../store/store";
import ModalCreateNotification from "./reusable/ModalCreateNotification";
import TableNotifications from "./reusable/TableNotifications";

const SectionNotifications = ({ userId }: PropsNotifications) => {
  const countPayments = useSelector(count);
  const pagePayments = useSelector(page);
  const dispatch = useDispatch<AppDispatch>();
  const loading = useSelector(isLoadingNotification);

  useEffect(() => {
    dispatch(setResetVars());
    dispatch(getNotificatios(userId));
  }, []);

  const newModal = useRef<CustomModalTypes>(null);

  const openNewModal = () => {
    if (newModal.current) {
      // @ts-ignore
      newModal.current.changeModal();
    }
  };

  return (
    <Box sx={{ padding: "0px" }}>
      <Box className="No-padding">
        <Box display="flex" justifyContent="end">
          <Button
            variant="outlined"
            color="secondary"
            size="small"
            onClick={openNewModal}
            startIcon={<AddCircleOutlineOutlinedIcon />}
            sx={{ mb: "15px" }}
          >
            <Box style={{ fontWeight: "bold", margin: 0 }}>
              Crear nueva notificación
            </Box>
          </Button>
        </Box>
        <Box>
          <hr className="Table-separater"></hr>
        </Box>
        {loading ? (
          <Box className="Loading-flex">
            <CircularProgress />
          </Box>
        ) : (
          <Fragment>
            <TableNotifications userId={userId} />
            <Box className="Pagination-invoices">
              <Pagination
                count={countPayments}
                shape="rounded"
                page={pagePayments + 1}
                onChange={(event: ChangeEvent<unknown>, value: number) => {
                  dispatch(handlePageChange(value - 1));
                  dispatch(getNotificatios(userId));
                }}
              />
            </Box>
          </Fragment>
        )}
        <ModalCreateNotification ref={newModal} userId={userId} />
      </Box>
    </Box>
  );
};

export default SectionNotifications;
