import { configureStore } from "@reduxjs/toolkit";
import notificationReducer from "../features/notification/notification";

export const store = configureStore({
  reducer: {
    notificationState: notificationReducer,
  },
});

export type RootState = ReturnType<typeof store.getState>;

export type AppDispatch = typeof store.dispatch;
