import { store } from "../store/store";
import { Provider } from "react-redux";
import {
  ThemeConfig, 
  PrivateRoute,  
} from "@enerbit/base";  
import SectionNotifications from "../pages/notification/components/SectionNotifications"; 
import { PropsNotifications } from "../models/notificationState";

export default function Routers ({userId}:PropsNotifications) {
  return <Provider store={store}>
  <ThemeConfig>
    <PrivateRoute>
      <SectionNotifications userId={userId} />
    </PrivateRoute>
  </ThemeConfig>
</Provider>
} 