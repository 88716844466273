
import { PropsNotifications } from "./models/notificationState";
import Routers from "./router/Routers"; 
import { initEnviroment } from "@enerbit/base";

initEnviroment({
  baseUrl: process.env.REACT_APP_BASE_URL
});

export default function Root({userId}:PropsNotifications) {  
  return  <Routers userId={userId} />  
}
