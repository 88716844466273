  import {  
    Box,   
    LoadingButton,
    Paper,
    TableContainer,
    Table,
    TableHead,
    TableRow,
    TableCell,
    TableBody, 
    moment,
    ImportExportIcon, 
    CustomAlert
  } 
  from "@enerbit/base"; 
  import { Fragment } from "react";
  import { useDispatch, useSelector } from "react-redux"; 
  import { 
    createForwarding, 
    hasErrorForwarding, 
    isLoadingForwarding, 
    notificationForwarding, 
    notifications, 
    setNotification, 
    setResetVars, 
    successForwarding 
  } from "../../../../features/notification/notification";    
  import { Notifications, PropsNotifications } from "../../../../models/notificationState";
  import { AppDispatch } from "../../../../store/store";

  const TableNotifications = ({userId}:PropsNotifications) => 
  {       
    const items = useSelector(notifications); 
    const loading = useSelector(isLoadingForwarding); 
    const hasError = useSelector(hasErrorForwarding); 
    const selectNotification = useSelector(notificationForwarding); 
    const success = useSelector(successForwarding); 
    const dispatch = useDispatch<AppDispatch>();  

    const forwarding = (row: Notifications) => {
      dispatch(setNotification(row.id))
      dispatch(createForwarding({
        user_id: userId,
        subject: row.subject,
        body: row.body,  
        expires_by_utc: new Date(moment().add(12, 'h').toString()).toISOString() //row.expires_by_utc.toString()
      }))
    }
   
    return(
      <Fragment> 
        { hasError && (
          <CustomAlert  
            onClose={() => dispatch(setResetVars())}
            sx={{mb:"13px"}}
            severity="error"
            text={"Ocurrió un error"}
          />
        )}
        { success && (
          <CustomAlert  
            onClose={() => dispatch(setResetVars())} 
            sx={{mb:"13px"}}
            severity="success"
            text={"Notificación reenviada exitosamente"}
          />
        )}
        <TableContainer component={Paper} sx={{boxShadow: "none"}}>
          <Table className="Table-invoices" aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell align="left">Asunto</TableCell>
                <TableCell align="left">Fecha y hora</TableCell>
                <TableCell align="left">Mensaje</TableCell> 
                <TableCell></TableCell> 
              </TableRow>
            </TableHead>
            <TableBody>
              { items && items.map((row, index) =>  (
                <TableRow
                  key={index}
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                >
                  <TableCell align="left"> {row.subject} </TableCell>
                  <TableCell align="left">{moment(row.created_at).format("YYYY-MM-DD HH:mm:ss")}</TableCell>
                  <TableCell align="left">{row.body}</TableCell>
                  <TableCell align="left">
                    <LoadingButton
                      startIcon={<ImportExportIcon />}
                      variant="contained"
                      color="primary"
                      size="small"
                      className="Loading-button Button-xs"  
                      loading={loading && row.id == selectNotification}
                      onClick={() => forwarding(row)}
                    >
                      <Box style={{fontWeight: "bold", margin: 0}}>Reenviar</Box>
                    </LoadingButton>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer> 
      </Fragment> 
    ) 
  };
  
  export default TableNotifications; 