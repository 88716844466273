import { AsyncThunkAction, createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { DataCreateNotification, NotificationsConsume, NotificationState } from "../../models/notificationState"; 
import {api} from "@enerbit/base" 
import { StateStorage } from "../../models/stateStorage";

export const getNotificatios = createAsyncThunk(
  "invoices/getNotificatios",
  async (user_id: string, thunkAPI:any ) => {   
    const state = thunkAPI.getState() as StateStorage;  
    let params = {  
      user_id,
      page: state.notificationState.page, 
      size: 5, 
    }  
    const res = await api.get("/notifications/", { params }); 
    return res.data;
  }
);

const sendNotification = async (data: DataCreateNotification, thunkAPI: { dispatch: (arg0: AsyncThunkAction<any, string, {}>) => any; }) => {     
  const res = await api.post("/notifications/", data); 
  await thunkAPI.dispatch(getNotificatios(data.user_id));
  return res.data;
}

export const createNotification = createAsyncThunk(
  "invoices/notifications",
  sendNotification
); 

export const createForwarding = createAsyncThunk(
  "invoices/notificationsForwarding",
  sendNotification
); 
 
const initialState: NotificationState = { 
  page: 0,
  count: 10,
  isLoadingNotification: false,
  isLoadingCreate: false,
  isLoadingForwarding: false,
  hasErrorForwarding: false,
  successForwarding: false,
  notificationForwarding: "",
  hasErrorCreate: false,
  successCreate: false,
  notifications: []
};

export const notificationSlice = createSlice({
  name: "notificationState",
  initialState,
  reducers: { 
    handlePageChange: (state, action: PayloadAction<number>) => {
      state.page = action.payload;
    },
    setResetVars: (state) => {
      state.isLoadingCreate = false;
      state.hasErrorCreate = false;
      state.successCreate = false;
      state.hasErrorForwarding = false; 
      state.successForwarding = false;
      state.isLoadingForwarding = false; 
      state.notificationForwarding = "";
    },
    setNotification: (state, action: PayloadAction<string>) => {
      state.notificationForwarding = action.payload; 
    },
  },
  extraReducers: (builder) => {
    builder
    .addCase(getNotificatios.pending.type, (state) => {
      state.isLoadingNotification = true;
    }) 
    .addCase(getNotificatios.rejected.type, (state) => {
      state.isLoadingNotification = false;
      state.count = 0;
      state.notifications = [];
    }) 
    .addCase(getNotificatios.fulfilled.type, (state, action: PayloadAction<NotificationsConsume>) => {
      state.count = action.payload.pages;
      state.notifications = action.payload.items;
      state.isLoadingNotification = false; 
    })
    .addCase(createNotification.pending.type, (state) => {
      state.isLoadingCreate = true;
    }) 
    .addCase(createNotification.rejected.type, (state) => {
      state.isLoadingCreate = false; 
      state.hasErrorCreate = true;
      state.successCreate = false;
    }) 
    .addCase(createNotification.fulfilled.type, (state ) => {
      state.hasErrorCreate = false; 
      state.successCreate = true;
      state.isLoadingCreate = false; 
    })
    .addCase(createForwarding.pending.type, (state) => {
      state.isLoadingForwarding = true;
    }) 
    .addCase(createForwarding.rejected.type, (state) => {
      state.isLoadingForwarding = false; 
      state.hasErrorForwarding = true;
      state.successForwarding = false;
    }) 
    .addCase(createForwarding.fulfilled.type, (state) => {
      state.hasErrorForwarding = false; 
      state.successForwarding = true;
      state.isLoadingForwarding = false; 
    })
  },
});

export const { handlePageChange, setResetVars, setNotification } = notificationSlice.actions;
  
export const page = (state: StateStorage) => state.notificationState.page;   
export const notificationForwarding = (state: StateStorage) => state.notificationState.notificationForwarding;   
export const hasErrorForwarding = (state: StateStorage) => state.notificationState.hasErrorForwarding;   
export const successForwarding = (state: StateStorage) => state.notificationState.successForwarding;    
export const isLoadingForwarding = (state: StateStorage) => state.notificationState.isLoadingForwarding;    
export const hasErrorCreate = (state: StateStorage) => state.notificationState.hasErrorCreate;   
export const successCreate = (state: StateStorage) => state.notificationState.successCreate;   
export const isLoadingCreate = (state: StateStorage) => state.notificationState.isLoadingCreate;   
export const isLoadingNotification = (state: StateStorage) => state.notificationState.isLoadingNotification;   
export const notifications = (state: StateStorage) => state.notificationState.notifications;   
export const count = (state: StateStorage) => state.notificationState.count;  

export default notificationSlice.reducer;
